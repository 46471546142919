<template>
  <div>
    <titulo-permissao
      :titulo="$t('ADMIN.PERMISSOES_PERFIL') + `: ` + item.nome"
    />
    <tabela :permissoes="item.permissoes" @salvar="salvarPermissao" />
  </div>
</template>

<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import rotas from '@/common/utils/rotas';
import mensagem from '@/common/utils/mensagem';
import Permisoes from '@/components/mixins/permissoes';

// Services:
import PerfisService from '@/common/services/perfis/perfis.service';

// Components:
// import { Cabecalho } from '@/components/headers/index';
import Tabela from './components/Tabela.vue';
import TituloPermissao from '@/views/acessos/perfis/components/TituloPermissao';

export default {
  mixins: [Permisoes],
  components: {
    // Cabecalho,
    TituloPermissao,
    Tabela,
  },
  data() {
    return {
      id: this.$route.params.id,
      item: [],
      perfil: null,
    };
  },
  mounted() {
    this.obterPerfilPorId();
  },
  methods: {
    // FUNÇÕES CRUD:
    obterPerfilPorId() {
      this.$store.dispatch(START_LOADING);
      PerfisService.obterPorId(this.id)
        .then(({ data }) => {
          this.item = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    salvarPermissao() {
      this.$store.dispatch(START_LOADING);
      PerfisService.salvarPermissao(this.item)
        .then(() => {
          this.obterPerfilPorId();
          mensagem.sucesso(this.$t('GERAL.SUCESSO'));
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    // FUNÇÕES AUXILIARES:
    irPara(rota, id) {
      rotas.irPara(rota, id);
    },
  },
};
</script>
