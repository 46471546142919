import store from "@/store/Store";

export default {
  data() {
    return {
      acessos: store.getters.acessos,
      idPagina: this.$router.currentRoute.meta.id,
    };
  },
  computed: {
    listaPermissoes() {
      return this.acessos.find((x) => x.acesso === this.idPagina);
    },
    podeCadastrar() {
      if (store.getters.admin) return true;
      return this.listaPermissoes.permissoes.includes("Criar");
    },
    podeEditar() {
      if (store.getters.admin) return true;
      return this.listaPermissoes.permissoes.includes("Atualizar");
    },
    podeExcluir() {
      if (store.getters.admin) return true;
      return this.listaPermissoes.permissoes.includes("Excluir");
    },
    podeVisualizar() {
      if (store.getters.admin) return true;
      return this.listaPermissoes.permissoes.includes("Visualizar");
    },
  },
};
