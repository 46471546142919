import ApiService from "@/common/api/api.service";

const PerfisService = {
  async listar(paginacao) {
    let { data } = await ApiService.get(`Perfil`, {
      params: paginacao,
    });
    return data;
  },
  async listarTodos() {
    let { data } = await ApiService.get(`Perfil/select`);
    return data;
  },
  async obterPorId(id) {
    let { data } = await ApiService.get(`Perfil/${id}`);
    return data;
  },
  async salvarPermissao(form) {
    let { data } = await ApiService.put(`Perfil`, form);
    return data;
  },
  async salvar(form) {
    let data = null;
    if (!form.id) data = ApiService.post(`Perfil`, form);
    else data = ApiService.put(`Perfil`, form);
    return data;
  },
  excluir(id) {
    return ApiService.delete(`Perfil/${id}`);
  },
};

export default PerfisService;
