<template>
  <topbar
    :titulo="titulo"
    :subtitulo="$t('ADMIN.PERFIS_DESCRICAO')"
    :breadcrumbs="breadcrumbs"
  >
    <template #header>
      <div class="d-flex justify-content-between ml-md-4">
        <div />
        <b-button @click="retornar()" class="mr-md-3">
          {{ $t('GERAL.VOLTAR') }}
        </b-button>
      </div>
    </template>
  </topbar>
</template>
<script>
import Topbar from '@/layouts/full-layout/horizontal-sidebar/Topbar.vue';

export default {
  props: {
    titulo: { type: String, default: '' },
    podeCadastrar: { type: Boolean, default: false },
  },
  components: { Topbar },
  data() {
    return {
      breadcrumbs: [
        { titulo: this.$t('ADMIN.CONFIGURACOES') },
        { titulo: this.$t('ADMIN.PERMISSOES_PERFIL') },
      ],
    };
  },
  methods: {
    retornar() {
      this.$router.push({ name: 'perfis' });
    },
  },
};
</script>
