<template>
  <b-table
    :items="permissoes"
    :fields="campos"
    :empty-text="$t('GERAL.NENHUM_REGISTRO')"
    head-variant="light"
    stacked="md"
    responsive
    show-empty
    striped
    hover
  >
    <template #cell(visualizar)="item">
      <input-checkbox
        v-model="item.item.permissoes"
        :options="['Visualizar']"
        @change="salvarPermissao"
      />
    </template>
    <template #cell(criar)="item">
      <input-checkbox
        v-model="item.item.permissoes"
        :options="['Criar']"
        @change="salvarPermissao"
      />
    </template>

    <!-- // TODO: trocar atualizar p/ editar -->
    <template #cell(editar)="item">
      <input-checkbox
        v-model="item.item.permissoes"
        :options="['Atualizar']"
        @change="salvarPermissao"
      />
    </template>
    <template #cell(excluir)="item">
      <input-checkbox
        v-model="item.item.permissoes"
        :options="['Excluir']"
        @change="salvarPermissao"
      />
    </template>
  </b-table>
</template>
<script>
// Componentes:
import { InputCheckbox } from '@/components/inputs';

export default {
  props: {
    permissoes: { type: Array, default: Array },
  },
  components: { InputCheckbox },
  data() {
    return {
      campos: [
        {
          key: 'acesso',
          label: this.$t('PERMISSOES.TELA'),
        },
        {
          key: 'visualizar',
          label: this.$t('PERMISSOES.VISUALIZAR'),
        },
        {
          key: 'criar',
          label: this.$t('PERMISSOES.CRIAR'),
        },
        {
          key: 'editar',
          label: this.$t('PERMISSOES.EDITAR'),
        },
        {
          key: 'excluir',
          label: this.$t('PERMISSOES.EXCLUIR'),
        },
      ],
    };
  },
  methods: {
    salvarPermissao(item) {
      this.$emit('salvar', item);
    },
  },
};
</script>
